<template>
  <div class="trading-ui-wrapper">
    <div class="trading-ui-header-root">
      <TradingUIHeader :risk-flags="riskFlags || ''" />
    </div>
    <div class="trading-ui-content">
      <div
        v-if="!isPrematch"
        class="trading-ui-content-scoreboard"
      >
        <EventView
          v-if="event"
          :event="event"
        />
      </div>
      <div class="trading-ui-content-main_markets-logs-parameters">
        <!-- Markets and Parameters -->
        <MainMarkets
          :markets="mainMarkets"
          :event-id="event?.id || ''"
          :is-usa-view="event?.isUSAView || true"
          :sport-id="event?.sportId || ''"
          :sport-label="event?.sport?.label || ''"
          :competition-id="event?.competition?.id || ''"
          :home-team="event?.home || {}"
          :away-team="event?.away || {}"
        />
        <AlternateMarkets
          v-if="!isPrematch"
          :markets="alternateMarkets"
          :event-id="event?.id || ''"
          :is-usa-view="event?.isUSAView || true"
          :sport-id="event?.sportId || ''"
          :sport-label="event?.sport?.label || ''"
          :competition-id="event?.competition?.id || ''"
          :home-team="event?.home || {}"
          :away-team="event?.away || {}"
          :is-prematch="isPrematch"
          :double-space="true"
          :markets-display-configuration="marketsDisplayConfiguration"
          :multi-view="false"
        />
        <ParametersRoot
          v-if="isPrematch && showParametersSection"
          :is-prematch="isPrematch"
        />
      </div>
      <div :class="['trading-ui-content-alternate_markets', { 'prematch': isPrematch }]">
        <MarketTiers
          v-if="event"
          :match-state="event.matchState"
          :limit-configuration="event.limitConfiguration"
          :in-prematch="event.inPrematch"
          :in-break="event.inBreak"
          @update:limit-configuration="updateLimitConfiguration"
        />
        <ParametersRoot
          v-if="!isPrematch && showParametersSection"
          :is-prematch="isPrematch"
        />
        <AlternateMarkets
          v-if="isPrematch"
          :markets="alternateMarkets"
          :event-id="event?.id || ''"
          :is-usa-view="event?.isUSAView || true"
          :sport-id="event?.sportId || ''"
          :sport-label="event?.sport?.label || ''"
          :competition-id="event?.competition?.id || ''"
          :home-team="event?.home || {}"
          :away-team="event?.away || {}"
          :is-prematch="isPrematch"
          :double-space="true"
          :markets-display-configuration="marketsDisplayConfiguration"
          :multi-view="false"
        />
      </div>
    </div>
    <div
      class="trading-ui-footer"
      v-if="isEventDisabled"
    >
      <EventDisabledIndicator />
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, watch, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  partition, filter, includes, isNil, orderBy, map,
} from 'lodash';
import { useOnlineStatus } from '@/composables';
import { overrideLimits } from '@/services/api';
import sportIds from '@/services/helpers/sports';
import Event from '@/models/event';
import SoccerEvent from '@/models/soccer-event';
import BasketballEvent from '@/models/basketball-event';
import FootballEvent from '@/models/football-event';
import UltimateEvent from '@/models/ultimate-event';
import HockeyEvent from '@/models/hockey-event';
import BaseballEvent from '@/models/baseball-event';
import MarketTiers from '@/components/common/MarketTiers';
import EventView from './event/EventView';
import ParametersRoot from './parameters/ParametersRoot';
import TradingUIHeader from './header/TradingUIHeader';
import MainMarkets from './markets/MainMarkets';
import AlternateMarkets from './markets/AlternateMarkets';
import { isAllowedUserRole } from '@/services/helpers/navbar';
import EventDisabledIndicator from '../../events/EventDisabledIndicator';
import { getAllowedRoles } from '@/../config/userRoles';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  SOCCER_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    TradingUIHeader,
    EventView,
    ParametersRoot,
    MainMarkets,
    MarketTiers,
    AlternateMarkets,
    EventDisabledIndicator,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const tradingEventSelectedFeed = computed(() => store.getters.tradingEventSelectedFeed);

    const event = computed(() => {
      if (!store.getters.tradingEvent) return null;
      if (store.getters.tradingEvent.alreadyMapped) return event;
      switch (store.getters.tradingEvent?.sportId) {
      case SOCCER_ID:
        return SoccerEvent(store.getters.tradingEvent, tradingEventSelectedFeed.value);
      case BASKETBALL_ID:
        return BasketballEvent(store.getters.tradingEvent, tradingEventSelectedFeed.value);
      case FOOTBALL_ID:
        return FootballEvent(store.getters.tradingEvent, tradingEventSelectedFeed.value);
      case ULTIMATE_ID:
        return UltimateEvent(store.getters.tradingEvent, tradingEventSelectedFeed.value);
      case HOCKEY_ID:
        return HockeyEvent(store.getters.tradingEvent, tradingEventSelectedFeed.value);
      case BASEBALL_ID:
        return BaseballEvent(store.getters.tradingEvent, tradingEventSelectedFeed.value);
      default:
        return Event(event, tradingEventSelectedFeed.value);
      }
    });
    const isPrematch = computed(() => !event.value?.matchState || includes(['N_A', 'PREMATCH'], event.value.matchState));

    const tradingEventMarkets = computed(() => {
      if (event.value?.matchState === 'LIVE') {
        return filter(store.getters.tradingEventMarkets, (market) => market.inPlay && market.resultingStatus !== 'RESULTED');
      }
      return store.getters.tradingEventMarkets;
    });

    const partitionedMarkets = computed(() => partition(tradingEventMarkets.value,
      (market) => market.marketType.params.SELECTIONS === 2
      && (market.marketCode === 'RESULT'
      || ((market.marketCode === 'POINT_HANDICAP' || market.marketCode === 'POINT_OVER_UNDER'
      || market.marketCode === 'GOAL_HANDICAP' || market.marketCode === 'GOAL_OVER_UNDER'
      || market.marketCode === 'RUN_HANDICAP' || market.marketCode === 'RUN_OVER_UNDER')
      && market.marketSummary?.isMainLine))));

    const marketsDisplayConfiguration = computed(() => orderBy(filter(store.getters.displayMarketsConfiguration,
      (market) => market.active), 'position'));

    const mainMarkets = computed(() => partitionedMarkets.value[0]);
    const alternateMarkets = computed(() => partitionedMarkets.value[1]);

    const showParametersSection = computed(() => {
      const allowedUserRoles = getAllowedRoles('playerSetup');
      const isAllowedRole = isAllowedUserRole(allowedUserRoles);

      return isAllowedRole;
    });

    const isEventDisabled = computed(() => event.value?.limbo);

    const updateLimitConfiguration = (limitConfiguration) => {
      store.dispatch('setTradingEvent', {
        ...store.getters.tradingEvent,
        limitConfiguration: {
          nodes: [{
            limitConfiguration,
          }],
        },
      });
      overrideLimits({
        ...limitConfiguration,
        eventId: event.value.id,
      });
    };

    const riskFlags = computed(() => {
      const riskFlagsArray = map(event.value?.riskFlags, (flag) => flag.label) || [];
      return riskFlagsArray.join('. ');
    });

    onMounted(() => {
      store.dispatch('loadTradingEvent', route.params.eventId);
      store.dispatch('subscribeToTradingUiMarkets', route.params.eventId);
    });

    onUnmounted(() => {
      store.dispatch('clearTradingUI');
    });

    watch(() => route.params.eventId, (newEventId) => {
      if (isNil(newEventId)) return; // Delegate to the clearTradingUI aciton
      store.dispatch('clearTradingUI');
      store.dispatch('loadTradingEvent', route.params.eventId);
      store.dispatch('setIsTradingEventSearchActive', false);
    });

    useOnlineStatus({
      onReconnect() {
        store.dispatch('reloadTradingEvent');
      },
    });

    return {
      event,
      isPrematch,
      mainMarkets,
      alternateMarkets,
      updateLimitConfiguration,
      marketsDisplayConfiguration,
      riskFlags,
      showParametersSection,
      isEventDisabled,
    };
  },
};
</script>

<style lang="scss">
.trading-ui-wrapper {
  height: calc(100vh - 69px);
  background-color: #FAFAFA;
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;

  .trading-ui-header-root {
    width: 100%;
    padding: 12px 0;
  }

  .trading-ui-content {
    height: calc(100% - 67px);
    width: 100%;
    display: flex;
    gap: 16px;
    overflow: hidden;

    .trading-ui-content-scoreboard {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 25%;
      min-height: 100px;
      min-width: 340px;
    }

    .trading-ui-content-main_markets-logs-parameters {
      flex-grow: 1;
      min-height: 100px;
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .trading-ui-content-alternate_markets {
      width: 25%;
      min-height: 100px;

      &.prematch {
        width: 50%;

        .trading-alternate-markets-wrapper {
          height: calc(100% - 221px);
        }
      }
    }
  }

  .trading-ui-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    background-color: #F0F0F0;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
}
</style>
